const API_BASE_URL = 'https://edunova.us-west-2.elasticbeanstalk.com/'; // Replace with your actual API base URL


const handleResponse = async (response) => {
    if (!response.ok) {
        // can extend this as needed for error types
        const error = await response.json();
        throw new Error(error.message || 'Something went wrong');
    }
    return response.json();
};

const getHeaders = (isJson = true) => {
    const headers = {};
    if (isJson) headers['Content-Type'] = 'application/json';
    // Add additional headers like authorization here if needed
    return headers;
};

export const apiGet = async (endpoint) => {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'GET',
        headers: getHeaders(),
    });
    return handleResponse(response);
};

export const apiPost = async (endpoint, data) => {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data),
    });
    return handleResponse(response);
};

export const apiPut = async (endpoint, data) => {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(data),
    });
    return handleResponse(response);
};

export const apiDelete = async (endpoint) => {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'DELETE',
        headers: getHeaders(),
    });
    return handleResponse(response);
};
