import './App.css';

import React from 'react';
import { apiGet, apiPost } from './api';
import { useState } from 'react';

const speak = (message) => {
  const utterance = new SpeechSynthesisUtterance(message);
  const voices = window.speechSynthesis.getVoices();
  utterance.voice = voices.find(voice => voice.name === 'Google US English');
  window.speechSynthesis.speak(utterance);
};

const TimeButton = () => {
  const fetchTime = async () => {
      try {
          const data = await apiGet('/time');
          alert(`Current time: ${data.time}`);
      } catch (error) {
          alert(`Error fetching time: ${error.message}`);
      }
  };

  return (
      <button onClick={fetchTime}>
          Get Current Time
      </button>
  );
};

const styles = {
    container: {
        width: '80%',
        maxWidth: '800px',
        margin: 'auto',
        paddingTop: '20px',
        display: 'flex',
        justifyContent: 'center',
    },
    textArea: {
        width: '100%',
        height: '300px',
        padding: '10px',
        fontSize: '16px',
        lineHeight: '1.5',
        resize: 'vertical',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxSizing: 'border-box',
    }
};

const ChatComponent = () => {
  const [inputText, setInputText] = useState('');

  const handleSend = async () => {
    if (inputText.trim()) {
      try {
        await apiPost('/general_chat', {"content": inputText});
        setInputText(''); // Clear the input box after sending
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  return (
    <div style={styles.container}>
      <input style={styles.textArea}
        type="text"
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        placeholder="Type your message here"
      />
      <button onClick={handleSend}>Send</button>
    </div>
  );
};

function App() {
  

  return (
    <div className="App">
      <header className="App-header">
        <ChatComponent />
        <TimeButton />
      </header>
    </div>
  );
}

export default App;
